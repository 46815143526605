var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Cache from 'api/Cache';
import Fetchable from 'api/Fetchable';
import { applyMethodQueue } from 'api/Utils';
// eslint-disable-next-line @typescript-eslint/no-var-requires
var debugModule = require('debug');
var debug = debugModule('Vitally.js');
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    debugModule.enable('Vitally.js*');
}
var Vitally = /** @class */ (function () {
    function Vitally() {
        var _this = this;
        this.api = process.env.VITALLY_API_ROOT;
        this.npsApi = process.env.VITALLY_NPS_API_ROOT;
        this.cache = new Cache();
        // for sharing api token with second-level API's
        this.credentials = new Map();
        // for defering the method calls of second-level API's
        this.namespaces = new Map();
        this.namespaceQueue = new Map();
        this.requireNps = function () { return __awaiter(_this, void 0, void 0, function () {
            var Nps;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, import(/* webpackChunkName: "nps" */ './Nps')];
                    case 1:
                        Nps = (_a.sent()).default;
                        return [2 /*return*/, new Nps({
                                api: this.npsApi,
                                credentials: this.credentials,
                                cache: this.cache,
                            })];
                }
            });
        }); };
        applyMethodQueue(window.Vitally, this);
    }
    Vitally.prototype.init = function (token, api, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (options.debug) {
            debugModule.enable('Vitally.js*');
        }
        debug('init', token, api);
        this.api = api !== null && api !== void 0 ? api : this.api;
        this.npsApi = api ? api.replace('api.', 'app.').replace('/analytics/v1', '/external/v1/nps') : this.npsApi;
        this.credentials.set('token', token);
        this.fetchable = new Fetchable(this.api, function () {
            return {
                headers: {
                    Authorization: 'Basic ' + _this.credentials.get('token'),
                },
                body: {
                    timestamp: new Date().toISOString(),
                },
            };
        });
    };
    Vitally.prototype.user = function (idOrProps, accountIdOrTraits, maybeTraits) {
        return __awaiter(this, void 0, void 0, function () {
            var props;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        debug("user", idOrProps, accountIdOrTraits, maybeTraits);
                        if (typeof idOrProps === 'string' && typeof accountIdOrTraits === 'string') {
                            props = normalizeProps('userId', 'traits', idOrProps, maybeTraits);
                            Object.assign(props, { accountId: accountIdOrTraits });
                        }
                        else {
                            props = normalizeProps('userId', 'traits', idOrProps, accountIdOrTraits);
                        }
                        this.cache.assign(props, 'userId', 'accountId');
                        return [4 /*yield*/, this.fetchable.post('/user', props)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Vitally.prototype.account = function (idOrProps, maybeTraits) {
        return __awaiter(this, void 0, void 0, function () {
            var props;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        debug("account", idOrProps, maybeTraits);
                        props = normalizeProps('accountId', 'traits', idOrProps, maybeTraits !== null && maybeTraits !== void 0 ? maybeTraits : {});
                        this.cache.assign(props, 'accountId');
                        if (props.traits && props.traits.name) {
                            this.cache.set('accountName', props.traits.name); // set but don't apply to this call
                        }
                        else {
                            this.cache.set('accountName', props.accountId);
                        }
                        return [4 /*yield*/, this.fetchable.post('/account', props)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Vitally.prototype.track = function (eventOrProps, maybeProperties) {
        return __awaiter(this, void 0, void 0, function () {
            var props;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        debug("track", eventOrProps, maybeProperties);
                        props = normalizeProps('event', 'properties', eventOrProps, maybeProperties);
                        this.cache.assign(props, 'userId');
                        if (!props.userId) {
                            return [2 /*return*/];
                        }
                        Object.assign(props, { accountId: undefined }); // clobber any cached accountId
                        return [4 /*yield*/, this.fetchable.post('/track', props)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Vitally.prototype.nps = function (methodName) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                debug("nps.".concat(methodName), { args: args });
                return [2 /*return*/, this.methodCall('nps', this.requireNps, methodName, args)];
            });
        });
    };
    Vitally.prototype.methodCall = function (namespaceName, importable, methodName, args) {
        return __awaiter(this, void 0, void 0, function () {
            var namespaces, namespace, namespaceQueue;
            var _this = this;
            return __generator(this, function (_a) {
                namespaces = this.namespaces;
                if (namespaces.has(namespaceName)) {
                    namespace = namespaces.get(namespaceName);
                    return [2 /*return*/, attemptToApplyMethod(namespaceName, namespace, methodName, args)];
                }
                namespaceQueue = this.namespaceQueue;
                if (!namespaceQueue.has(namespaceName)) {
                    namespaceQueue.set(namespaceName, (function () { return __awaiter(_this, void 0, void 0, function () {
                        var implementation;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, importable()];
                                case 1:
                                    implementation = _a.sent();
                                    namespaces.set(namespaceName, implementation);
                                    return [2 /*return*/, attemptToApplyMethod(namespaceName, implementation, methodName, args)];
                            }
                        });
                    }); })());
                }
                else {
                    namespaceQueue.get(namespaceName).then(function () { return __awaiter(_this, void 0, void 0, function () {
                        var namespace;
                        return __generator(this, function (_a) {
                            namespace = namespaces.get(namespaceName);
                            return [2 /*return*/, attemptToApplyMethod(namespaceName, namespace, methodName, args)];
                        });
                    }); });
                }
                return [2 /*return*/];
            });
        });
    };
    return Vitally;
}());
export default Vitally;
function normalizeProps(stringPropName, objectPropName, stringOrBody, maybeObject) {
    var _a;
    if (typeof stringOrBody === 'string') {
        return _a = {},
            _a[stringPropName] = stringOrBody,
            _a[objectPropName] = maybeObject,
            _a;
    }
    return stringOrBody;
}
function attemptToApplyMethod(namespaceName, namespace, methodName, args) {
    return __awaiter(this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!namespace[methodName]) {
                        debug("No method found ".concat(namespaceName, ".").concat(methodName, "(...)"));
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, namespace[methodName].apply(namespace, args)];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    err_1 = _a.sent();
                    debug("Unable to apply Vitally.".concat(namespaceName, "('").concat(methodName, "', ...)"), args);
                    debug('Error', err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
